import { navigate } from "gatsby"
import React, { useCallback, useContext, useEffect } from "react"
import { Flex } from "theme-ui"
import Text from "../components/base/text/text"
import StoreContext from "../context/store-context"
import { trackPurchase } from "../services/analytics"
import { createClient } from "../util/client"

const PaymentCompleted = () => {
  const { clearCart } = useContext(StoreContext)
  const client = createClient()

  const completeOrder = useCallback(async () => {
    const cachedCart = localStorage.getItem("medusa::cache")
    const cart = JSON.parse(cachedCart)

    let order

    try {
      // Check if order is created by webhook
      order = await client.orders.retrieveByCartId(cart.id)
    } catch (error) {
      order = await client.carts.complete(cart.id)
    }

    if (order) {
      clearCart()
      let params = []

      if ("data" in order) {
        order = order.data
      } else if ("order" in order) {
        order = order.order
      }

      params.push(`o=${order.id}`)

      trackPurchase(order)
      navigate(`/done?${params.join("&")}`, {
        replace: true,
      })
    } else {
      navigate(`/checkout`, {
        replace: true,
      })
    }
  }, [clearCart, client.carts, client.orders])

  useEffect(() => {
    completeOrder()
  }, [])

  return (
    <Flex sx={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
      <Flex sx={{ flexDirection: " column", textAlign: "center" }}>
        <Text>Your order will be on the court in a couple of seconds.</Text>
        <Text>Hang Tight.</Text>
      </Flex>
    </Flex>
  )
}

export default PaymentCompleted
